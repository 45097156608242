.dots {
  display: flex;

  padding: 10px 0;

  justify-content: center;
}

.dot {
  border: none;

  width: 0.625rem;

  height: 0.625rem;

  background: #a7e6ff;

  border-radius: 50%;

  margin: 0 5px;

  padding: 5px;

  cursor: pointer;
}

.blue-dot {
  border: none;

  width: 0.625rem;

  height: 0.625rem;

  background: #ccebff;

  border-radius: 50%;

  margin: 0 5px;

  padding: 5px;

  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.blue-dot:focus {
  outline: none;
}

.dot.active {
  background: #418099;
}

.blue-dot.active {
  background: #0099ff;
}

