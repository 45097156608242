@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  li {
    @apply p-4
  }

  body {
    @apply font-be-vietnam-pro
  }

  /* button {
    @apply bg-gradient-to-r from-BrandGreen-800 to-BrandGreen-500 border-BrandGreen-800 text-Neutral-0 rounded-lg
    hover:bg-Neutral-0 hover:text-BrandGreen-800
  } */

}